import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step oauth`}</strong>{` -- authorization and single sign-on using OAuth & OIDC`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step oauth
[--provider=<provider>] [--client-id=<client-id> --client-secret=<client-secret>]
[--scope=<scope> ...] [--bare [--oidc]] [--header [--oidc]]
[--prompt=<prompt>] [--auth-param=<key=value>]

step oauth
--authorization-endpoint=<authorization-endpoint>
--token-endpoint=<token-endpoint>
--client-id=<client-id> --client-secret=<client-secret>
[--scope=<scope> ...] [--bare [--oidc]] [--header [--oidc]]
[--prompt=<prompt>] [--auth-param=<key=value>]

step oauth [--account=<account>]
[--authorization-endpoint=<authorization-endpoint>]
[--token-endpoint=<token-endpoint>]
[--scope=<scope> ...] [--bare [--oidc]] [--header [--oidc]]
[--prompt=<prompt>] [--auth-param=<key=value>]

step oauth --account=<account> --jwt
[--scope=<scope> ...] [--header] [-bare] [--prompt=<prompt>]
[--auth-param=<key=value>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step oauth`}</strong>{` command implements the OAuth 2.0 authorization flow.`}</p>
    <p>{`OAuth is an open standard for access delegation, commonly used as a way for
Internet users to grant websites or applications access to their information on
other websites but without giving them the passwords. This mechanism is used by
companies such as Amazon, Google, Facebook, Microsoft and Twitter to permit the
users to share information about their accounts with third party applications or
websites. Learn more at `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/OAuth"
      }}>{`https://en.wikipedia.org/wiki/OAuth`}</a>{`.`}</p>
    <p>{`This command by default performs the authorization flow with a preconfigured
Google application, but a custom one can be set combining the flags
`}<strong parentName="p">{`--client-id`}</strong>{`, `}<strong parentName="p">{`--client-secret`}</strong>{`, and `}<strong parentName="p">{`--provider`}</strong>{`. The provider value
must be set to the OIDC discovery document (.well-known/openid-configuration)
endpoint. If Google is used this flag is not necessary, but the appropriate
value would be be `}<a parentName="p" {...{
        "href": "https://accounts.google.com"
      }}>{`https://accounts.google.com`}</a>{` or
`}<a parentName="p" {...{
        "href": "https://accounts.google.com/.well-known/openid-configuration"
      }}>{`https://accounts.google.com/.well-known/openid-configuration`}</a></p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--provider`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`, `}<strong parentName="p">{`--idp`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
OAuth provider for authentication`}</p>
    <p><strong parentName="p">{`--email`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`, `}<strong parentName="p">{`-e`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Email to authenticate`}</p>
    <p><strong parentName="p">{`--console`}</strong>{`, `}<strong parentName="p">{`-c`}</strong>{`
Complete the flow while remaining only inside the terminal.
This flag defaults to use the Device Authorization Grant flow.`}</p>
    <p><strong parentName="p">{`--console-flow`}</strong>{`=`}<inlineCode parentName="p">{`flow`}</inlineCode>{`
The alternative OAuth `}<inlineCode parentName="p">{`flow`}</inlineCode>{` to use for input constrained devices.`}</p>
    <p><inlineCode parentName="p">{`console-flow`}</inlineCode>{` is a case-insensitive string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`device`}</strong>{`: Use the Device Authorization Grant (`}<a parentName="p" {...{
            "href": "https://datatracker.ietf.org/doc/html/rfc8628#section-3.2"
          }}>{`https://datatracker.ietf.org/doc/html/rfc8628#section-3.2`}</a>{`) flow`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`oob`}</strong>{`: Use the Out of Band (OOB) flow`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--client-id`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
OAuth Client ID`}</p>
    <p><strong parentName="p">{`--client-secret`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
OAuth Client Secret`}</p>
    <p><strong parentName="p">{`--account`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
JSON file containing account details`}</p>
    <p><strong parentName="p">{`--authorization-endpoint`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
OAuth Authorization Endpoint`}</p>
    <p><strong parentName="p">{`--device-authorization-endpoint`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
OAuth Device Authorization Endpoint`}</p>
    <p><strong parentName="p">{`--token-endpoint`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
OAuth Token Endpoint`}</p>
    <p><strong parentName="p">{`--header`}</strong>{`
Output HTTP Authorization Header (suitable for use with curl)`}</p>
    <p><strong parentName="p">{`--oidc`}</strong>{`
Output OIDC Token instead of OAuth Access Token`}</p>
    <p><strong parentName="p">{`--bare`}</strong>{`
Only output the token`}</p>
    <p><strong parentName="p">{`--scope`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
OAuth scopes`}</p>
    <p><strong parentName="p">{`--auth-param`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
OAuth additional authentication parameters to include as part of the URL query.
Use this flag multiple times to add multiple parameters. This flag expects a
'key' and 'value' in the format '--auth-param "key=value"'.`}</p>
    <p><strong parentName="p">{`--prompt`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Whether the Authorization Server prompts the End-User for reauthentication and consent.
OpenID standard defines the following values, but your provider may support some or none of them:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`none`}</strong>{`: The Authorization Server MUST NOT display any authentication or consent user interface pages.
An error is returned if an End-User is not already authenticated or the Client does not have
pre-configured consent for the requested Claims or does not fulfill other conditions for
processing the request.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`login`}</strong>{`: The Authorization Server SHOULD prompt the End-User for reauthentication. If it cannot
reauthenticate the End-User, it MUST return an error, typically login_required.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`consent`}</strong>{`: The Authorization Server SHOULD prompt the End-User for consent before returning information
to the Client. If it cannot obtain consent, it MUST return an error, typically consent_required.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`select_account`}</strong>{`: The Authorization Server SHOULD prompt the End-User to select a user account. This enables an
End-User who has multiple accounts at the Authorization Server to select amongst the multiple
accounts that they might have current sessions for. If it cannot obtain an account selection
choice made by the End-User, it MUST return an error, typically account_selection_required.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--jwt`}</strong>{`
Generate a JWT Auth token instead of an OAuth Token (only works with service accounts)`}</p>
    <p><strong parentName="p">{`--listen`}</strong>{`=`}<inlineCode parentName="p">{`address`}</inlineCode>{`
Callback listener `}<inlineCode parentName="p">{`address`}</inlineCode>{` (e.g. ":10000")`}</p>
    <p><strong parentName="p">{`--listen-url`}</strong>{`=`}<inlineCode parentName="p">{`url`}</inlineCode>{`
The redirect_uri `}<inlineCode parentName="p">{`url`}</inlineCode>{` in the authorize request (e.g. "`}<a parentName="p" {...{
        "href": "http://127.0.0.1:10000%22"
      }}>{`http://127.0.0.1:10000"`}</a>{`)`}</p>
    <p><strong parentName="p">{`--redirect-url`}</strong>{`=`}<inlineCode parentName="p">{`url`}</inlineCode>{`
The `}<inlineCode parentName="p">{`url`}</inlineCode>{` to open in the system browser when the OAuth flow is successful.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Do the OAuth 2.0 flow using the default client:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step oauth
`}</code></pre>
    <p>{`Redirect to localhost instead of 127.0.0.1:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step oauth --listen localhost:0
`}</code></pre>
    <p>{`Redirect to a fixed port instead of random one:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step oauth --listen :10000
`}</code></pre>
    <p>{`Redirect to a fixed url but listen on all the interfaces:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step oauth --listen 0.0.0.0:10000 --listen-url http://127.0.0.1:10000
`}</code></pre>
    <p>{`Get just the access token:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step oauth --bare
`}</code></pre>
    <p>{`Get just the OIDC token:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step oauth --oidc --bare
`}</code></pre>
    <p>{`Use a custom OAuth2.0 server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step oauth --client-id my-client-id --client-secret my-client-secret \\
  --provider https://example.org
`}</code></pre>
    <p>{`Use the Device Authorization Grant flow for input constrained clients:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step oauth --client-id my-client-id --client-secret my-client-secret --console-flow device
`}</code></pre>
    <p>{`Use the Out Of Band flow for input constrained clients:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step oauth --client-id my-client-id --client-secret my-client-secret --console-flow oob
`}</code></pre>
    <p>{`Use the default OAuth flow for input constrained clients:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step oauth --client-id my-client-id --client-secret my-client-secret --console
`}</code></pre>
    <p>{`Use additional authentication parameters:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step oauth --client-id my-client-id --client-secret my-client-secret \\
  --provider https://example.org --auth-param "access_type=offline"
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      